import service, {buildUpload} from "@/conf/service";

export const stateCode = {
    /**
     * 发布
     */
    RELEASE: 1,
    /**
     * 草稿
     */
    DRAFT: 2,
    /**
     * 隐藏
     */
    HIDE: 3,
}

export function getContent(query) {
    return service(
        {
            url: '/api/content',
            method: 'get',
            params: query,
        }
    )
}

export function getContentList(query) {
    return service(
        {
            url: '/api/content/list',
            method: 'get',
            params: query,
        }
    )
}

export function addContent(data) {
    return service(
        {
            url: '/api/content',
            method: "post",
            data: data
        }
    )
}

export function deleteContent(id) {
    return service(
        {
            url: '/api/content/delete/' + id,
            method: "post",
        }
    )
}

export function updateContent(data) {
    return service(
        {
            url: '/api/content/update',
            method: "post",
            data: data
        }
    )
}

export function hideOrUnhide(data) {
    return service(
        {
            url: '/api/content/hideOrUnhide',
            method: "post",
            data: data
        }
    )
}

export function addResourceDesc(fileType, data, files) {
    return buildUpload('/api/file/add/' + fileType, data, files)
}

export function setTheCoverContent(data) {
    return service(
        {
            url: '/api/content/setTheCoverContent',
            method: "post",
            data: data
        }
    )
}

export function cancelTheCoverContent(data) {
    return service(
        {
            url: '/api/content/cancelTheCoverContent',
            method: "post",
            data: data
        }
    )
}

export function getTheCoverContent() {
    return service(
        {
            url: '/api/content/getTheCoverContent',
            method: "get",
        }
    )
}

export function getPublicContent(query) {
    return service(
        {
            url: '/public/content',
            method: 'get',
            params: query,
        }
    )
}

export function getPublicContentList(query) {
    return service(
        {
            url: '/public/content/list',
            method: 'get',
            params: query,
        }
    )
}
