<template>
  <v-navigation-drawer
      app
      :dark="$vuetify.theme.dark"
      v-model="copyMini"
      :persistent="true"
      :stateless="true">
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar style="text-align: center">
          <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list-item-group
        nav
        color="primary"
        v-model="currentRouterSort"
        dense>
      <v-list-item link v-for="(item) in routers" :key="item.meta.sort" @click="changeRouter(item)">
        <v-list-item-icon v-if="item.meta.icon">
          <v-icon>{{ item.meta.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-navigation-drawer>

</template>

<script>
export default {
  name: "PCNavigation",
  props: {
    mini: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    routers: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      copyMini: null,
      copyRouters: [],
      currentRouter: {},
      currentRouterSort: 0,
    }
  },
  watch: {
    mini(newVal) {
      this.copyMini = newVal;
      console.log('copyMini', this.copyMini);
    },
    routers(data) {
      this.copyRouters = data;
    },
  },
  async mounted() {
    this.copyMini = this.mini;
    this.copyRouters = this.routers;
    await this.$nextTick();
    this.currentRouter = this.copyRouters.filter(item =>
        item.meta.parentRoute + (item.path ? '/' + item.path : '') === this.$route.path)[0]
    this.currentRouterSort = this.currentRouter.meta.sort;
    this.$emit('selectedRouter', this.currentRouter)
  },
  methods: {
    changeRouter(router) {
      const newRouter = router.meta.parentRoute + (router.path ? '/' + router.path : '');
      this.currentRouter = router
      this.currentRouterSort = router.meta.sort;
      if (newRouter !== this.$route.path) {
        this.$router.push(newRouter)
        this.$emit('selectedRouter', router)
      }
    },
  }
};
</script>

<style scoped>

</style>
