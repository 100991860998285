<template>
  <customize-dialog
      ref="contentDialog"
      :transition="'dialog-bottom-transition'"
      :show-dialog="copyContentDialogShow"
      :loading="loading"
      :fullscreen="true"
      :persistent="true"
      :card-actions-show="false"
      :show-toolbar="true">
    <template v-slot:toolbarRightButtons>
      <v-btn
          icon
          small
          @click="closeDialog">
        <v-icon class="actions-icon">mdi-close</v-icon>
      </v-btn>
    </template>
    <template v-slot:content>
      <div style="width: 100%;height: 100%;"
           :style="{background: $vuetify.theme.dark ? 'RGB(54, 59, 64)' : ''}"
           :class="'markdown-box' + ($vuetify.theme.dark ? '-dark' : '')">
        <iframe
            style="width: 100%;border: 0;height: 99%"
            :srcdoc="$vuetify.theme.dark ? nightHtml : lightHtml"></iframe>
      </div>
    </template>
  </customize-dialog>


</template>
<script>
import CustomizeDialog from "@/components/CustomizeDialog/index.vue";
import {getPublicMarkdownResourceDescList, getHtmlById} from "@/api/admin/resourceDesc";

export default {
  name: 'MarkdownIndex',
  components: {
    CustomizeDialog,
  },
  props: {
    contentDialogShow: {
      type: Boolean,
      default: false
    },
    contentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      copyContentId: '',
      copyContentDialogShow: true,
      loading: false,
      html: '',
      someList: [],
      lightHtml: '',
      nightHtml: '',
      s: false,

    }
  },
  watch: {
    contentDialogShow(data) {
      this.copyContentDialogShow = data;
    },
  },
  mounted() {
    this.copyContentDialogShow = this.contentDialogShow;
    this.copyContentId = this.contentId;
    this.initContent();
  },
  methods: {
    closeDialog() {
      this.$emit('closeContentDialog')
    },
    async initContent() {
      const getResourceDescListRes = await getPublicMarkdownResourceDescList({contentId: this.copyContentId});
      this.resources = getResourceDescListRes.data.markdownVos
      if (!this.resources || this.resources.length === 0) {
        return
      }
      for (const item of this.resources) {
        const res = await getHtmlById(item.fileId);
        if (item.night === '0') {
          this.lightHtml = res;
        } else {
          this.nightHtml = res;
        }
      }
    },
  }
}
</script>
<style scoped>
.markdown-box {

}

/deep/ .markdown-box-night {
  background: RGB(54, 59, 64) !important;
}
</style>