<template>
  <v-container
      ref="scrollContainer"
      fluid
      :class="'container-' + $vuetify.breakpoint.name"
      style="padding: 0;"
      :style="{
        backgroundColor: $vuetify.theme.dark ? '#353739' : '#FFFFFF'}"
      v-resize="onResize"
  >
    <v-row
        no-gutters
        align-content="center"
        justify="center"
        v-for="(item,index) in colArray" :key="index"
        :class="getRowClass(index)"
        :style="{backgroundColor: $vuetify.theme.dark ? '#353739' : '#FFFFFF',}"
    >
      <v-col
          align-self="center"
          :lg="colConf.lg"
          :xl="colConf.xl"
          :md="colConf.md"
          :sm="colConf.sm"
          :cols="colConf.xs"
          :offset-sm="0"
          :offset-md="0"
          :offset-xs="0"
          :offset-lg="0"
          :offset-xl="0"
      >
        <v-container
            class="pl-lg-0 pr-lg-0 pl-sm-0 pr-sm-0 pl-24 pr-24"
            :class="'card-' + $vuetify.breakpoint.name"
            style="width: 100%;">
          <v-row>
            <v-col order="0" cols="12" :sm="item.theCover ? 6 : 12" :md="item.theCover ? 6 : 12"
                   :lg="item.theCover ? 6 : 12" class="pl-0">
              <v-skeleton-loader
                  :class="$vuetify.breakpoint.name"
                  type="button@1"
                  v-if="item.showSkeleton"
              ></v-skeleton-loader>
              <v-skeleton-loader
                  :class="$vuetify.breakpoint.name"
                  v-if="item.showSkeleton"
                  style="width: 100%;"
                  type="card-heading"
              ></v-skeleton-loader>
              <v-skeleton-loader
                  :class="$vuetify.breakpoint.name"
                  v-if="item.showSkeleton"
                  :width="item.theCover ? '50%' : '25%'"
                  type="list-item"
              ></v-skeleton-loader>
              <div :class="'content-card'+(item.showSkeleton? '-none':'')">
                <div>
                  <v-chip
                      class="text-subtitle-2"
                      :outlined="true"
                      small
                      @click="$emit('changeTag',(item.tag + ''))"
                  >
                    {{ getChip(item) }}
                  </v-chip>
                </div>
                <a
                    :class="'text-underline-hover' + ($vuetify.theme.dark ? '-dark ' : ' ')
                    + ' font-weight-black text-h4'" v-if="item.title"
                    style="line-height: 3rem"
                    v-text="item.title"
                    @click="openDialog(item)"
                />
                <div :class="('card-time'+ ($vuetify.theme.dark ? '-dark ' : ' '))
                +'text-body-2 time-' + index" v-text="formatTime(item.createdTime)"
                     style="margin-top: 12px;"
                />
              </div>
            </v-col>
            <v-col order="1" cols="12" sm="6" md="6" lg="6" class="pa-0" v-if="item.theCover">
              <v-skeleton-loader
                  :width="'100%'"
                  style="width:100%;aspect-ratio: 1"
                  :class="$vuetify.breakpoint.name"
                  v-if="item.showSkeleton"
                  type="image"
              ></v-skeleton-loader>
              <div :class="'content-card-'+(item.showSkeleton? 'none':'')" @click="openDialog(item)">
                <v-img
                    :aspect-ratio="1"
                    :width="'100%'"
                    v-if="item.theCover"
                    class="white--text align-end"
                    :src="item.theCover"
                >
                </v-img>
              </div>
            </v-col>
          </v-row>

          <v-divider class="mt-5" v-if="(index+1) < colArray.length "></v-divider>
        </v-container>
      </v-col>

    </v-row>
    <div style="width: 100%" v-if="page.total > 10">
      <v-container fluid>
        <v-row
            justify="center"
            align="center">
          <v-container class="max-width">
            <v-pagination
                :disabled="pageDisabled"
                class="my-4"
                v-model="offset"
                :length="page.length"
                @next="nextPage"
                @previous="nextPrevious"
            ></v-pagination>
          </v-container>
        </v-row>
      </v-container>
    </div>
    <front-content-index
        v-if="contentVisible && contentType !== 3"
        @closeContentDialog="closeContentDialog"
        :content-dialog-show="contentVisible"
        :content-id="contentId"></front-content-index>
    <markdown-index
        v-if="contentVisible && contentType === 3"
        @closeContentDialog="closeContentDialog"
        :content-id="contentId"
        :content-dialog-show="contentVisible">

    </markdown-index>
  </v-container>
</template>

<script>


import {getPublicContentList} from "@/api/admin/content";
import FrontContentIndex from "@/views/home/detail/index.vue";
import {colConf} from "@/views/home/js/layout";
import MarkdownIndex from "@/views/home/detail/markdown/index.vue";
import common from "@/util/common";

export default {
  name: "ContentPage",
  components: {MarkdownIndex, FrontContentIndex},
  data() {
    return {
      page: {
        length: 0,
        total: 0
      },
      pageDisabled: true,
      offset: 1,
      colConf: colConf,
      btnGroup: ["全部"],
      contentVisible: false,
      contentId: '',
      contentType: null,
      colArray: [],
      contentPortrait: {
        height: 0,
        paddingTop: 0,
        cardHeight: 0,
      },
      contentHorizontal: {
        width: 0,
        cardWidth: 0,
        paddingLeft: 0,
        rowColPaddingLeft: 0,
        rowColPaddingRight: 0,
      },
      colPadding: 0,
      col: {
        lg: 6,
        xl: 4,
        md: 8,
        sm: 12,
        xs: 12,
      },
      show: false,
      middleElementIsAnEve: true,
      theCoverInfo: {
        id: '',
        img: '',
      },
      listSize: 0,
      searchParams: {
        offset: 1,
        limit: 10
      },
    };
  },
  computed: {},
  watch: {
    async offset(val) {
      this.searchParams.offset = val;
      await this.inti();
      this.colArray.forEach(item => item.showSkeleton = false);
      this.pageDisabled = false;
      this.$emit('listLoadEnd', this.listSize);
    },
  },
  created() {
  },
  async mounted() {
    await this.inti()
    this.colArray.forEach(item => item.showSkeleton = false);
    this.pageDisabled = false;
    this.$emit('listLoadEnd', this.listSize);
    // this.openDialog(this.colArray[0])
  },
  methods: {
    getChip(item) {
      if (item.type === 1) {
        return '旅游';
      }
      if (item.type === 2) {
        return '小猫';
      }
      if (item.type === 3) {
        return '文档';
      }
      return ''
    },
    nextPage() {
      if ((this.offset + 1) > this.page.length) {
        return
      }
      this.offset++;
    },
    nextPrevious() {
      if ((this.offset - 1) < 1) {
        return
      }
      this.offset--;
    },
    formatTime(item) {
      if (!item) {
        return "";
      }
      return item.split(" ")[0];
    },
    getRowClass(index) {
      return 'row-' + (index === 0 ? 'start' : index === this.colArray.length - 1 ? 'end' : 'middle');
    },
    async inti() {
      this.colArray = (() => {
        let array = [];
        for (let i = 0; i < 2; i++) array.push({showSkeleton: true})
        return array;
      })()
      this.pageDisabled = true;
      await this.delayedFunction();
    },
    async search(tag) {
      if (this.searchParams.tag !== tag) {
        this.offset = 1;
        this.searchParams.offset = 1;
        this.searchParams.limit = 10;
      }
      this.searchParams.tag = tag;
      await this.inti();
      this.colArray.forEach(item => item.showSkeleton = false);
      this.pageDisabled = false;
      this.$emit('listLoadEnd', this.listSize);
    },
    delayedFunction() {
      return new Promise((resolve) => {
        const startTime = Date.now();
        getPublicContentList(this.searchParams)
            .then(async res => {
              if (res.code === 0) {
                this.page.total = res.data.total;
                this.page.length = Math.ceil(this.page.total / this.searchParams.limit);
                const endTime = Date.now();
                const executionTime = endTime - startTime;
                await this.loadCoverSize(res.data.rows);
                if (executionTime > 1000) {
                  resolve();
                } else {
                  setTimeout(() => {
                    resolve()
                  }, 1000 - executionTime);
                }
              }
            })
      });
    },
    loadCoverSize(rows) {
      return new Promise((resolve) => {
        this.listSize = rows.length;
        if (rows.length === 0) {
          resolve();
        } else {
          for (let i = 0; i < rows.length; i++) {
            rows[i].showSkeleton = true;
            rows[i].theCover = rows[i].theCover ? (common.staticSource + '/public/file/100-webp-' + rows[i].theCover) : '';
          }
          this.pageDisabled = true;
          this.colArray = rows;
          resolve()
        }

      })
    },
    getHeightByClassName(className) {
      const elementsByClassName = document.getElementsByClassName(className);
      if (elementsByClassName && elementsByClassName.length > 0) {
        const style = window.getComputedStyle(elementsByClassName[0]);
        return parseInt(style.height.replaceAll("px", ""))
      }
      return 0;
    },
    openDialog(item) {
      if (item.showSkeleton) {
        return
      }
      this.contentId = item.id;
      this.contentType = item.type;
      this.contentVisible = true;
    },
    closeContentDialog() {
      this.contentVisible = false;
    },
    getHeightFactor() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'md':
          return 1.9
        case 'sm':
        case 'lg':
        case 'xl':
          return 1
      }
      return 1;
    },
    getWidthFactor() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 0.9
        case 'lg':
        case 'md':
          return 0.9
        case 'sm':
        case 'xl':
          return 0.8
      }
      return 1;
    },
    getEmptyFactor() {
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          return 0.05
        case 'lg':
        case 'sm':
        case 'xl':
          return 0.1
      }
      return 0.1;
    },
    onResize() {
      this.$vuetify.breakpoint.update()
      // 处理屏幕高度变化的逻辑
      this.contentPortrait.height = this.$vuetify.breakpoint.height / this.getHeightFactor();
      const emptyArea = Math.round(this.contentPortrait.height * this.getEmptyFactor());
      this.contentPortrait.paddingTop = emptyArea - (emptyArea / 2);
      this.contentPortrait.cardHeight = this.contentPortrait.height - emptyArea
      // 处理屏幕宽度变化的逻辑
      this.contentHorizontal.width = window.innerWidth
      this.contentHorizontal.cardWidth = Math.round(window.innerWidth * this.getWidthFactor())
      this.contentHorizontal.paddingLeft = (window.innerWidth - this.contentHorizontal.cardWidth) / 2
      this.contentHorizontal.rowColPaddingLeft = this.contentHorizontal.paddingLeft;
      this.contentHorizontal.rowColPaddingRight = this.contentHorizontal.paddingLeft;
    },


  },
  component: {}
}
</script>

<style scoped>
/deep/ .v-skeleton-loader__button {
  border-radius: 4px;
  height: 22px;
  width: 50px;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding-left: 10px;
  padding-right: 10px;
}

.card-cover-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
//padding-left: 10px; //padding-right: 10px;
}

.card-xs {
  padding-left: 24px;
  padding-right: 24px;
}

.card-title {
  width: fit-content;
//overflow: hidden; //text-overflow: ellipsis; //display: -webkit-box; //-webkit-box-orient: vertical; //-webkit-line-clamp: 1; font-size: 1.75rem;
}

.card-time {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.card-time-dark {
  color: #DBDBDC;
}

.card-title_hover {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: aquamarine;
}

::v-deep .no-margin-p {
  margin-bottom: 0 !important;
}

.row {
  margin: 0;
}

.lg-center {
  padding: 12px 12px;
}

.lg-left {
  padding: 12px 12px 12px 0;
}

.lg-right {
  padding: 12px 0 12px 12px;
}


.md-left {
  padding: 12px 12px 12px 0;
}

.md-right {
  padding: 12px 0 12px 12px;
}

.sm-single {
  padding: 12px 0 12px 0;
}

.xs-single {
  padding: 12px 0 12px 0;
}

/* 元素进入动画 */
.fade-enter-active {
  transition: opacity .5s;
}

.fade-enter {
  opacity: 0;
}

/* 元素离开动画 */
.fade-leave-active {
  transition: opacity .5s;
}

.fade-leave-to {
  opacity: 0;
}

/deep/ .theme--dark.v-skeleton-loader .v-skeleton-loader__image {
  height: 100%;
}

/deep/ .theme--light.v-skeleton-loader .v-skeleton-loader__image {
  height: 100%;
}

/deep/ .theme--dark.v-skeleton-loader .v-skeleton-loader__article {
  background: none;

}

/deep/ .theme--dark.v-skeleton-loader .v-skeleton-loader__card-heading {
  background: none;

}

/deep/ .theme--dark.v-skeleton-loader .v-skeleton-loader__list-item {
  background: none;

}

/deep/ .v-skeleton-loader__heading {
  width: 90%;

}

/deep/ .theme--light.v-skeleton-loader .v-skeleton-loader__heading {
  margin-left: 0;
}

/deep/ .theme--light.v-skeleton-loader .v-skeleton-loader__list-item {
  padding-left: 0;
}

.row-start {
  margin: 0;
}

.content-card-none {
  position: fixed;
  left: -9999px !important;
}

.container-lg {
  width: calc(100vw - 220px)
}

.container-sm {
  width: calc(100vw - 180px)
}

.container-md {
  width: calc(100vw - 260px)
}

/deep/ .theme--light.v-sheet--outlined {
  border: none;

}

.btn-group {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  font-size: 1.075rem;
  font-weight: 500;
  line-height: 1.45rem;
  color: #373633;
}

/deep/ .toolbar-lg .v-toolbar__content {
  padding-right: 0 !important;
}

/deep/ .toolbar-xs .v-toolbar__content {
  padding-right: 24px !important;
}

.btn-mr-0 {
  margin-right: 0;
}

.btn-mr-1 {
  margin-right: 0.4rem;
}
</style>
