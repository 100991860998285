import Vue from 'vue';
import App from './App.vue';
import vuetify from '@/plugins/vuetify' ;// path to vuetify export
import '@mdi/font/css/materialdesignicons.css';
import './assets/common.css';
import '@/conf/permission'
import 'video.js/dist/video-js.css';
import Vuex from 'vuex';
import store from './store';
import router from './conf/router';
import VueRouter from 'vue-router';
import onresize from "@/store/module/onresize";
import settings, {registerColorScheme} from "@/util/setting";
import common from "@/util/common";
import vibrant from "node-vibrant/dist/vibrant.worker.min";
import service from '@/conf/service';
import {VSnackbar} from 'vuetify/lib';
import message from 'vuetify-message-snackbar';
import './assets/variables.scss';
import confirm from '@/util/confirm';
import {registerStorageListener} from "@/conf/localstorage";
import Vconsole from 'vconsole'

console.log('process.env.ENV', process.env.ENV)
if (window.location.host !== "snowsnowsnow.life") {
    let vConsole = new Vconsole()
    vConsole.setSwitchPosition(100, 200)
    Vue.use(vConsole);
}
// Vue.use(animate);
Vue.use(message);
Vue.prototype.axios = service
Vue.prototype.onresize = onresize;
Vue.prototype.settings = settings;
Vue.prototype.common = common;
Vue.prototype.vibrant = vibrant;
Vue.prototype.$confirm = confirm

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.component('v-snackbar', VSnackbar);
new Vue({
    router,
    store,
    render: h => h(App),
    vuetify,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || {x: 0, y: 0}
    },
    created() {
        // 注册 localStorage 变化监听器
        registerStorageListener(this);
        registerColorScheme(this);
    }
}).$mount('#app');
