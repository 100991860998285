<template>
  <v-app id="inspire">
    <PCNavigation
        v-if="loadEnd"
        :mini="drawer"
        :routers="routers"
        @selectedRouter="selectedRouter"></PCNavigation>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ currentRouter.meta.title}}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import PCNavigation from "@/views/admin/components/pcNavigation/index.vue";
import {adminRouter} from "@/conf/router";

export default {
  name: "AdminIndex",
  components: { PCNavigation},
  data() {
    return {
      windowHeight: 0,
      right: null,
      mini: false,
      screenInfo: {},
      drawer: true,
      group: null,
      routers: [],
      currentRouter: {meta: {title: ''}},
      routerViewWidth: '',
      sidebarWidth: '240px',
      contentLeft: '-240px',
      loadEnd: false,
    }
  },
  async mounted() {
    this.initRouters();
    console.log('123123')
    this.loadNavigationStateByLocalStorage();
    await this.$nextTick()
    if (this.$vuetify.breakpoint.mobile) {
      this.sidebarWidth = '0';
      this.contentLeft = '0'
    } else {
      this.sidebarWidth = this.mini ? '56px' : '240px';
      this.contentLeft = this.mini ? '-56px' : '-240px'
    }

    this.routerViewWidth = this.$store.state.screenInfo.width - (this.mini ? 56 : 240)
  },
  watch: {
    '$store.state.screenInfo': {
      handler: async function (newValue) {
        this.windowHeight = newValue.height;
        this.routerViewWidth = this.$store.state.screenInfo.width - (this.mini ? 56 : 240)
      },
      deep: true
    },
  },
  computed: {},
  methods: {
    selectedRouter(router) {
      this.currentRouter = router;
    },
    changeDark() {
      this.dark.changeDark();
    },
    initRouters() {
      adminRouter.forEach(item => this.routers.push(item))
      this.routers.sort((pre, next) => pre.meta.sort - next.meta.sort)
      this.loadEnd = true;
    },
    loadNavigationStateByLocalStorage() {
      const settings = JSON.parse(localStorage.getItem('settings'));
      const mini = settings.mini;
      if (mini) {
        this.mini = mini === '1';
      } else {
        settings.mini = '0';
        this.mini = settings.mini === '1';
        localStorage.setItem('settings', JSON.stringify(settings));
      }
    },
    changeNavigationState() {
      if (this.isMobile) {
        this.drawer = !this.drawer;
      } else {
        this.mini = !this.mini;
        const settings = JSON.parse(localStorage.getItem('settings'));
        settings.mini = this.mini ? '1' : '0';
        localStorage.setItem('settings', JSON.stringify(settings));
        this.$store.commit('setNavigationState', this.mini);
        this.sidebarWidth = this.mini ? '56px' : '240px'
        this.contentLeft = this.mini ? '-56px' : '-240px'
      }

    },
  }

}
</script>

<style scoped>
/*.wrap {*/
/*  max-width: 100%;*/
/*  margin: 0 auto;*/
/*  overflow: hidden;*/
/*}*/

.content {
  height: 100%;
  float: right;
  width: 100%;
  margin-left: v-bind(contentLeft);
}

.content-inner {
  margin-left: v-bind(sidebarWidth);
}

.sidebar {
  float: left;
  width: v-bind(sidebarWidth);
}
</style>
