<template>
  <v-navigation-drawer
      v-model="copySettingDrawerShow"
      app
      :height="'100%'"
      :width="getDrawerWidth()"
      right
      temporary
      ref="drawer"
      :persistent="true"
      :stateless="true"
  >
    <v-toolbar :height="getToolbarHeight()" elevation="0" class="pl-2 pr-2">
      <v-toolbar-title class="buttons-group-title">设置</v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="padding: 20px 0 4px">
        <v-btn icon small @click="$emit('closeSettingDrawer')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

    </v-toolbar>

    <div v-for="(item,index) in buttonsGroup" :key="index"
         :style="{ marginTop:( index > 0 ? '20px' : '')}" class="buttons-group">
      <div v-if="item.show">
        <v-divider></v-divider>
      </div>
      <v-row v-if="item.show">
        <v-col :offset="1" class="pt-6 pb-0">
          <div class="text-subtitle-2" v-text="item.title"></div>
        </v-col>
      </v-row>
      <v-row v-if="item.show">
        <v-col
            v-for="(button,buttonIndex) in item.buttons"
            class="pt-1 pb-1"
            :cols="5"
            :offset="(buttonIndex % 2) === 0 ? 1 : 0"
            :key="buttonIndex">
          <div class="setting-box">
            <div :class="'setting-button'
            + (button.selected ? '-selected' : '')
            + ($vuetify.theme.dark ? '-dark' : '')">
              <v-btn
                  @click="changeSetting(index,buttonIndex,button.fun,button.param);"
                  depressed
                  elevation="2"
                  raised
              >{{ button.title }}
                <v-icon
                    right
                    style="width: 24px;height: 24px;font-size: 24px;"
                >
                  {{ button.icon }}
                </v-icon>
              </v-btn>
            </div>

          </div>
        </v-col>
      </v-row>

    </div>
  </v-navigation-drawer>
</template>

<script>


export default {
  name: "SettingIndex",
  components: {},
  props: {
    settingDrawerShow: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    settingDrawerShow(data) {
      this.copySettingDrawerShow = data;
    },
  },
  data() {
    return {
      buttonsGroup: [
        {
          title: '主题',
          show: true,
          buttons: [
            {
              title: '跟随系统', icon: 'mdi-brightness-auto',
              selected: false,
              fun: 'openColorSchemeBySystem'
            },
            {
              title: '浅色主题',
              icon: 'mdi-brightness-5',
              selected: false,
              fun: 'changeColorAndColorSchemeBySystem',
              param: '0'
            },
            {
              title: '黑色主题',
              icon: 'mdi-brightness-4',
              selected: false,
              fun: 'changeColorAndColorSchemeBySystem',
              param: '1'
            },
          ],
        },
        {
          title: '资源压缩比',
          show: false,
          buttons: [
            {
              title: '100%',
              icon: 'mdi-circle-slice-8',
              selected: false,
              fun: 'setResourceCompressionRatio',
              param: '100'
            },
            {
              title: '70%',
              icon: 'mdi-circle-slice-7',
              selected: false,
              fun: 'setResourceCompressionRatio',
              param: '70'
            },
            {
              title: '50%',
              icon: 'mdi-circle-slice-4',
              selected: false,
              fun: 'setResourceCompressionRatio',
              param: '50'
            },
          ],
        },
        {
          title: 'LivePhotos 视频加载',
          show: true,
          buttons: [
            {
              title: '自动加载',
              icon: 'mdi-download',
              selected: false,
              fun: 'changeLivePhotosAutoLoad',
              param: '1'
            },
            {
              title: '鼠标悬停',
              icon: 'mdi-mouse',
              selected: false,
              fun: 'changeLivePhotosAutoLoad',
              param: '0'
            },
          ],
        },
      ],
      copySettingDrawerShow: false,
      group: null,
    }
  },
  mounted() {
    this.copySettingDrawerShow = this.settingDrawerShow;
    this.inti();
  },
  methods: {
    initTheme(settings) {
      const darkOrLight = this.buttonsGroup[0];
      if (settings.colorSchemeBySystem === '1') {
        darkOrLight.buttons.forEach((item, index) => item.selected = index === 0)
      } else if (settings.dark === '0') {
        darkOrLight.buttons.forEach((item, index) => item.selected = index === 1)
      } else {
        darkOrLight.buttons.forEach((item, index) => item.selected = index === 2)
      }
    },
    initResourceCompressionRatio(settings) {
      const resourceCompressionRatio = this.buttonsGroup[1];
      if (settings.resourceCompressionRatio === '100') {
        resourceCompressionRatio.buttons.forEach((item, index) => item.selected = index === 0)
      } else if (settings.resourceCompressionRatio === '70') {
        resourceCompressionRatio.buttons.forEach((item, index) => item.selected = index === 1)
      } else {
        resourceCompressionRatio.buttons.forEach((item, index) => item.selected = index === 2)
      }
    },
    initLivePhotos(settings) {
      const livePhotosAutoOrMouse = this.buttonsGroup[2];
      if (settings.livePhotos.autoLoad === '1') {
        livePhotosAutoOrMouse.buttons.forEach((item, index) => item.selected = index === 0)
      } else {
        livePhotosAutoOrMouse.buttons.forEach((item, index) => item.selected = index === 1)
      }
    },
    inti() {
      const settings = this.settings.getSettings();
      this.initTheme(settings);
      this.initLivePhotos(settings);
      this.initResourceCompressionRatio(settings);
    },
    changeSetting(groupIndex, buttonIndex, func, param) {
      for (let _groupIndex = 0; _groupIndex < this.buttonsGroup.length; _groupIndex++) {
        if (groupIndex !== _groupIndex) {
          continue;
        }
        const group = this.buttonsGroup[_groupIndex].buttons;
        group.forEach((button, _buttonIndex) => button.selected = buttonIndex === _buttonIndex);
      }
      this.settings[func](param);

    },
    getToolbarHeight() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return '53px';
      } else {
        return '57px';
      }
    },
    getDrawerWidth() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return '75%';
      } else if (this.$vuetify.breakpoint.mdOnly || this.$vuetify.breakpoint.smOnly) {
        return '35%';
      } else {
        return '310px';
      }
    },

  }
}
</script>

<style scoped>


.buttons-group-title {
  font-size: 0.975rem;
  font-weight: 500;
  line-height: 1.75rem;
  padding: 20px 0 4px;
  display: flex;
  flex-direction: row;
}

.buttons-group-icon {
  line-height: 1.75rem;
  padding: 20px 0 4px;
  display: flex;
  flex-direction: row;
}

.setting-box {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.setting-button {
  height: 48px;
  width: 130px;

  .v-btn {
    background: #EEEEEE;
    height: 48px;
    width: 130px;
  }
}

.setting-button-dark {
  height: 48px;
  width: 130px;

  .v-btn {
    height: 48px;
    width: 130px;
  }
}

.setting-button-selected {
  height: 48px;
  width: 130px;

  .v-btn {
    background: #3072C5;

    height: 48px;
    width: 130px;


  }

  /deep/ .v-btn__content {
    color: #FFFFFF !important;
  }
}

.setting-button-selected-dark {
  height: 48px;
  width: 130px;

  .v-btn {
    background: #2196F3;

    height: 48px;
    width: 130px;
  }

  /deep/ .v-btn__content {
    color: #FFFFFF !important;
  }
}

.setting-icon {
  display: flex;
}
</style>
