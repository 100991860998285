<template>
  <div ref="livePhotos" :style="copyLivePhotoStyle"/>
</template>

<script>
import * as LivePhotosKit from 'livephotoskit';

export default {
  name: 'LivePhotos',
  props: {
    photoSrc: {
      type: String,
      required: true
    },
    videoSrc: {
      type: String,
      required: true
    },
    livePhotoStyle: {
      type: Object,
      default: null
    },

  },
  data() {
    return {
      copyLivePhotoStyle: {},
      player: null,
    }
  },
  watch: {
    livePhotoStyle: {
      handler(data) {
        if (!this.videoSrc) {
          return
        }
        this.copyLivePhotoStyle = data;
        this.$nextTick(() => this.player.updateSize(true));
      },
      deep: true
    },
  },
  // beforeDestroy() {
  //   // this.player = null;
  //   console.log('123')
  //   this.player.remove();
  // },
  mounted() {
    this.copyLivePhotoStyle = this.livePhotoStyle;
    const image = new Image();
    image.src = this.photoSrc;
    image.alt = "";
    image.onload = () => this.initializeLivePhoto(image)
  },

  methods: {
    initializeLivePhoto(image) {
      console.log('image', image)
      if (LivePhotosKit) {
        const container = this.$refs.livePhotos;
        this.player = new LivePhotosKit.augmentElementAsPlayer(container, {
          videoSrc: this.videoSrc,
          photo: image,
          proactivelyLoadsVideo: this.settings.getSettingsJson().livePhotos.autoLoad === '1',
        });
      } else {
        console.error('LivePhotosKit is not loaded');
      }
    },
  },

}
</script>

<style scoped>

</style>
