import service from "@/conf/service";

export function getResourceDescList(query) {
    return service(
        {
            url: '/api/file/list',
            method: 'get',
            params: query,
        });
}

export function deleteResourceDesc(id) {
    return service(
        {
            url: '/api/file/delete/' + id,
            method: "post",
        }
    )
}



export function getPublicMarkdownResourceDescList(query) {
    return service(
        {
            url: '/public/file/markdown/list',
            method: 'get',
            params: query,
        });
}

export function getHtmlById(id) {
    return service(
        {
            url: '/public/file/100-html-' + id,
            method: 'get',
        });
}
