import service from "@/conf/service";


export function removeByContentId(data) {
    return service(
        {
            url: '/api/file/remove/byContentId',
            method: 'post',
            data: data
        }
    )
}
export function getFileList(query) {
    return service(
        {
            url: '/api/file/list',
            method: 'get',
            params: query,
        });
}
export function getPublicFileList(query) {
    return service(
        {
            url: '/public/file/list',
            method: 'get',
            params: query,
        });
}